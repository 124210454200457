export const emdrCommentsData = [
  {
    desc: 'Благодаря этому сервису я начала понимать свои проблемы лучше. Спасибо за помощь!',
    name: 'Вера',
    age: '32 года',
    title: 'Я начала понимать свои проблемы лучше',
    img: 'person6.svg'
  },
  {
    desc: 'Атмосфера в сервисе дружелюбная и принимающая. Благодаря помощи консультанта службы поддержки, я смогла найти нужного специалиста. Сессии не всегда мне даются легко, но я чувствую, что на верном пути. Спасибо за поддержку!',
    name: 'Мария',
    age: '35 лет',
    title: 'Я чувствую, что на верном пути',
    img: 'person1.svg'
  },
  {
    desc: 'Вот уже полгода занимаюсь с психологом сервиса YouTalk. На данный момент все устраивает. Очень удобный интерфейс личного кабинета. Все понятно и доступно — от записи на сессию до оплаты. К клиентскому сервису легко обратиться с какой-то проблемой. Всегда быстро и эффективно все решают. Так что я доволен',
    name: 'Тимофей',
    age: '21 год',
    title: 'Все понятно и доступно — от записи на сессию до оплаты',
    img: 'person12.svg'
  },
  {
    desc: 'Удобный личный кабинет, квалифицированные психологи, отзывчивая поддержка. Сервис полностью оправдал мои ожидания!',
    name: 'Иван',
    age: '42 года',
    title: 'Сервис полностью оправдал мои ожидания!',
    img: 'person5.svg'
  },
  {
    desc: 'Достаточно интересный психологический сервис со стильным дизайном и удобным интерфейсом. Выгодно отличается от своих конкурентов. Рекомендую',
    name: 'Лилия',
    age: '34 года',
    title: 'Рекомендую',
    img: 'person0.svg'
  },
  {
    desc: 'Давно знала, что мне нужно обратиться за помощью, но поиск психолога казался таким сложным процессом, поэтому оттягивала этот момент как могла. В клиентской службе сервиса Youtalk мне очень помогли в этом: задали точные вопросы о моем запросе и пожеланиях, объяснили разницу между подходами. Психолога подобрали буквально за пару часов. Здорово, когда можно обратиться к знающим людям, и они тебе помогут. С первой сессии я почувствовала, что попала к профессионалу. Работа пошла очень продуктивно. Мне бывает трудно раскрыться, но психолог так бережно подобрала ко мне ключик, что мне было легко это сделать.',
    name: 'Екатерина',
    age: '29 лет',
    title: 'С первой сессии я почувствовала, что попала к профессионалу',
    img: 'person7.svg'
  }
]
