import React from 'react'
import {
  Content,
  DescriptionWrapper,
  InfoContainer,
  ListItem,
  ListWrapper,
  Wrapper
} from './QueriesEmdr.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import { TitleWrapper } from '../Common.styles'

export const QueriesEmdr = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="#fff">
              <Title.H2>
                Запросы, с которыми работают ДПДГ психологи YouTalk
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="#fff">
              <Text.Large semiBold>
                Терапия по методу EMDR эффективно работает с самыми разными
                проблемами:
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <ListWrapper>
            <ListItem>
              <Text.Large semiBold>ПТСР</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>КПТСР</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>Страхи и фобии</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>Тревожные расстройства</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>Депрессия</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>Переживание горя</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>
                Работа с последствиями физического, сексуализированного и
                психологического насилия
              </Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>Последствия буллинга</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>Низкая самооценка</Text.Large>
            </ListItem>
            <ListItem>
              <Text.Large semiBold>Другие</Text.Large>
            </ListItem>
          </ListWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
