import React from 'react'
import ruporSVG from '../../../../static/img/Emdr/rup.svg'
import styled from 'styled-components'
import {
  Container,
  Content,
  IconWrapper,
  TextWrapper,
  TitleSection,
  Wrapper
} from './WhenNeedEmdr.styles'
import { Grid, Text } from '../../../../youtalk-storybook/src/ui'

export const RuporSVG = styled.img.attrs(() => ({
  src: ruporSVG
}))``

export const WhenNeedEmdr = () => (
  <Wrapper>
    <section>
      <Grid>
        <Container>
          <Content>
            <IconWrapper>
              <RuporSVG />
            </IconWrapper>
            <TextWrapper>
              <TitleSection>
                Когда нужна онлайн консультация EMDR-терапевта?
              </TitleSection>
              <Text.Large semiBold>
                EMDR-терапия будет особенно полезна в ситуациях, когда ваши
                проблемы возникли из-за травматичных событий.
              </Text.Large>
              <Text.Large semiBold>
                Это может быть детская травма (отвержение, холодность родителей
                и т.д.) или катастрофическое событие во взрослом возрасте:
                аварии, стихийные бедствия, ситуации, угрожавшие вашей жизни и
                здоровью, жизнь в зоне военных действий и т.п.
              </Text.Large>
            </TextWrapper>
          </Content>
        </Container>
      </Grid>
    </section>
  </Wrapper>
)
