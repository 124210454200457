import React from 'react'
import {
  Content,
  DescriptionWrapper,
  ImageOne,
  ImageThree,
  ImageTwo,
  ImageWrapper,
  InfoContainer,
  TitleWrapper,
  Wrapper
} from './WhatIsEmdr.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const WhatIsEmdr = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper>
            <Title.H2>
              Что такое EMDR (ДПДГ) терапия и как она работает?
            </Title.H2>
          </TitleWrapper>
          <InfoContainer>
            <DescriptionWrapper>
              <Text.Large semiBold>
                EMDR дословно расшифровывается как «десенсибилизация и
                переработка движением глаз». EMDR-терапия — это метод
                психотерапии, который помогает людям справиться с последствиями
                травматических событий: ментальными проблемами, переживаниями и
                т.п. Она использует движение глаз или другие способы стимуляции
                мозга.
              </Text.Large>
            </DescriptionWrapper>
            <ImageWrapper>
              <ImageOne />
            </ImageWrapper>
          </InfoContainer>
          <InfoContainer>
            <ImageWrapper>
              <ImageTwo />
            </ImageWrapper>
            <DescriptionWrapper>
              <Text.Large semiBold>
                ЕМДР базируется на теории Адаптивной Переработки Информации
                (АПИ). Человек во время травмы учится определенным образом жить:
                например, быть удобным для своих близких, реагировать атакой на
                громкие звуки и т.п. А когда травматичное событие заканчивается,
                он приносит этот паттерн во все сферы своей жизни и страдает от
                этого. Это называется дезадаптивным навыком, с которым в ДПДГ
                терапии и будут работать.
              </Text.Large>
            </DescriptionWrapper>
          </InfoContainer>
          <InfoContainer>
            <DescriptionWrapper>
              <Text.Large semiBold>
                Задача EMDR-терапевта в том, чтобы помочь клиенту соединиться с
                самым ранним и самым сильным воспоминанием человека, и
                переработать его. Когда травматический опыт перерабатывается на
                сессиях с терапевтом, то восстанавливается ощущение ясности и
                спокойствия, присутствия здесь и сейчас, появляется опыт опоры
                на себя, и те триггеры, которые раньше цепляли, уже не имеют
                влияния на человека.{' '}
              </Text.Large>
            </DescriptionWrapper>
            <ImageWrapper>
              <ImageThree />
            </ImageWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
